

<template>
  <ul class="bordered-items">
    <li v-for="(notif, index) in list" :key="index" :class="['p-0 notification', {'unread-bg': !notif.is_seen }]">
      <a href="javascript:void(0)" @click.prevent="navigateTo(notif)" class="text-inherit flex px-3 py-3 justify-between hover:opacity-50">
        <div class="flex items-start">
          <feather-icon :icon="notif.icon" class="h-6 w-6" :svgClasses="['text-success stroke-current mr-1']"></feather-icon>
          <div class="mx-2 leading-none">
            <span class="font-medium block notification-title text-success"> {{ notif.title }}</span>
            <small style="font-size: 10px;">{{ notif.text | truncate(50)  }}</small>
          </div>
        </div>
        <small style="font-size: 10px;" class="mt-1 whitespace-no-wrap">{{ notif.created_at | date_ago }}</small>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
    name: "notification-list",
    props: {
      list: {
        type: Array,
        required: true,
      },
    },
    methods: {
      navigateTo(notif){
        if(!notif.is_seen){
          this.$store.dispatch("notification/markNotifAsRead", notif.id);
        }
        this.$router.push({path: notif.url });
      },
    },
}
</script>

<style type="text/css" scoped>
 .notification-dropdown .notification.unread-bg{
  color: #1e1e1e;
  background: #ebedef !important;
 }

 [dir] ul.bordered-items > li:not(:last-of-type):not([class*='shadow']) {
    border-bottom: 1px solid #0000002e !important;
}
</style>
